import React, { useEffect, useState } from "react";
import { CardImages } from "../Resources/CardImages";
import { useNavigate } from "react-router-dom";
import CustomizedDialogs from "./Modal";
import Swal from 'sweetalert2';

const HomeManuall = () => {
  const [manualImages, setManualImages] = useState([]);
  const [manualHand, setManualHand] = useState([]);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [isEmptyName, setIsEmptyName] = useState(false);
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);

  const triggerWebhook = async () => {
    var link, hand;
      link =
        "https://personalitypokergames.com/Result?keys=" +
        manualHand.toString() +
        "&name=" +
        name;
      hand = manualHand.toString();
    try {
      const response = await fetch(
        "https://personality-poker-42daff2399da.herokuapp.com/send-data",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Name: name,
            Email: email,
            Hand: hand,
            Link: link,
          }),
        }
      );
      if (response.ok) {
        console.log("Data sent to server successfully.");
      } else {
        console.error("Error sending data to server.");
      }
    } catch (error) {
      console.error("Error sending data to server:", error);
    }
  };

  const navigate = useNavigate();

  const goToPageTwo = () => {
    
      if (manualHand.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please select all five cards before proceeding to the next page.'
        });
        return;
      }
      for (let i = 0; i < 5; i++) {
        if (!manualHand[i]) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Please select all five cards before proceeding to the next page.',
          });
          return; 
        }
      }
    if ((name && email) || (name==="test" && email==="test")) {
      triggerWebhook();
      const link =
          "https://personalitypokergames.com/Result?keys=" +
          manualHand.toString();
        navigate("/Result", {
          state: { manualHand, link },
        });
    } else {
      if (!name) {
        setIsEmptyName(true);
      }
      if (!email) {
        setIsEmptyEmail(true);
      }
    }
  };

  const emailValidator = (email) => {
    // Regular expression for a valid email address
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Use the test method to check if the email matches the regex
    return emailRegex.test(email);
  };

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 500);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 500);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Option to select cards manually
  const handleSelection = (index, value, selector) => {
    if (selector === 1) {
      const array = [...manualHand];
      if (array[index]) {
        const str = String(array[index]);
        array[index] = value + str.slice(1);
      } else {
        array[index] = value;
      }
      setManualHand(array);
    } else if (selector === 2) {
      const array = [...manualHand];
      if (array[index]) {
        const str = String(array[index]);
        array[index] = str.charAt(0) + value;
      } else {
        array[index] = value;
      }
      setManualHand(array);
    }
  };

  const cardNumber = [
    {
      name: "2",
      value: "2",
    },
    {
      name: "3",
      value: "3",
    },
    {
      name: "4",
      value: "4",
    },
    {
      name: "5",
      value: "5",
    },
    {
      name: "6",
      value: "6",
    },
    {
      name: "7",
      value: "7",
    },
    {
      name: "8",
      value: "8",
    },
    {
      name: "9",
      value: "9",
    },
    {
      name: "10",
      value: "10",
    },
    {
      name: "Ace",
      value: "a",
    },
    {
      name: "Jack",
      value: "j",
    },
    {
      name: "Queen",
      value: "q",
    },
    {
      name: "King",
      value: "k",
    },
  ];
  const cardSuit = [
    {
      name: "Diamond",
      value: "d",
    },
    {
      name: "Heart",
      value: "h",
    },
    {
      name: "Club",
      value: "c",
    },
    {
      name: "Spade",
      value: "s",
    },
  ];
  // modal
  const [selectedCardId, setSelectedCardId] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleImageSelection = (cardKey) => {
    const array = [...manualImages];
    array[selectedCardId] = CardImages[cardKey];
    setManualImages(array);

    const arrayKeys = [...manualHand];
    arrayKeys[selectedCardId] = cardKey;
    setManualHand(arrayKeys);
  };

  const handleCardClick = (index) => {
    setSelectedCardId(index); // Set the selected card ID
    setIsModalOpen(true); // Open the modal
  };
  return (
    <>
      <div className={isWideScreen ? "container" : ""}>
        {/* Logo */}
        <div className="container my-4">
          <a href="https://personalitypokergames.com">
            <img
              className="my-2 mx-2"
              style={{ maxWidth: "80%", height: "auto" }}
              src="./images/logo.png"
              alt="poker-logo"
            />
          </a>
        </div>
        {/* Accordion */}
        <div
          className="fluid-container"
          style={{
            // Sets color inside div
            background: "white",
            border: "3px solid rgba(128, 128, 128, 0.477)",
            borderRadius: "7px",
            boxShadow: "5px 5px 10px 8px grey",
          }}
        >
          <div>
            <ul
              id="navLaptop"
              style={{
                margin: 0,
                padding: 0,
                backgroundColor: "#bdc5e0b3",
              }}
              className="row d-flex justify-content-between"
            >
              <li
                className="col-6"
                style={{
                  borderRadius: "0px 15px 15px 0px",
                  backgroundColor: "white",
                }}
              >
                <span>
                  <img
                    style={{ width: window.innerWidth > 500 ? "45px" : "25px" }}
                    src="images/icons8-1-70.png"
                  />
                </span>
                <span style={{ fontFamily: "'Times New Roman', Times, serif" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PLAY GAME
                </span>
              </li>
              <li
                className="col-6"
                style={{ fontFamily: "fantasy", color: "#909094" }}
              >
                <span>
                  <img
                    style={{ width: window.innerWidth > 500 ? "45px" : "25px" }}
                    src="images/icons8-2-80.png"
                  />
                </span>
                <span style={{ fontFamily: "'Times New Roman', Times, serif" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SEE YOUR RESULTS
                </span>
              </li>
              {/* <li className="col-4" style={{backgroundColor:"#bdc5e0b3",color: "#909094"}}>
              <span>
                <img style={{width:"22px"}} src="images/icons8-3-80.png" />
              </span>
              <span> SHARE WITH FRIENDS</span>
            </li> */}
            </ul>
            <hr />
          </div>
          {/* first margin */}
          <div
            className={`accordion ${
              window.innerWidth > 770 ? "m-4" : "my-4 mx-1"
            }`}
          >
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  style={{ backgroundColor: "white" }}
                  className="accordion-button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                >
                  <strong>
                    <h5 id={window.innerWidth > 550 ? "" : "mobileHeading"}>
                      PERSONALITY POKER VIDEO GAME
                    </h5>
                  </strong>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body">
                  {isWideScreen ? (
                    <div className="d-flex">
                      <p id="accordionText" >
                        Welcome to the <strong>Personality Poker®</strong>{" "}
                        online hand analysis tool.
                        <br />
                        <br />
                        To use this, you must already have your five cards selected by playing with the physical decks. If you don't have your cards selected, please go to{" "}
                        <span style={{ wordBreak: 'break-all' }}>www.personalitypokergames.com</span>
                        <br />
                        <br />
                       Enter the five cards you selected, one at a time. Select the suit and number from the dropdown options.
                      
                       After entering all five cards, enter your name and email address to get your results. 
                       
                        <br />
                      </p>
                      <img
                        id="accordionImage"
                        src="./images/Poker.png"
                        alt="Poker-bookwaccordionImagecardscover"
                      />
                    </div>
                  ) : (
                    <div className="row">
                      <p id="mobileText" className="col-8">
                        Welcome to the <strong>Personality Poker®</strong>{" "}
                        online hand analysis tool.
                      </p>
                      <img
                        id="mobileImage"
                        className="col-4"
                        src="./images/Poker.png"
                        alt="Poker-bookwcardscover"
                      />
                        <p id="mobileRemainText">
                          To use this, you must already have your five cards selected by playing with the physical decks. If you don't have your cards selected, please go to 
                          <span style={{ wordBreak: 'break-all' }}>www.personalitypokergames.com</span>
                          
                            After entering all five cards, enter your name and email address to get your results.
                          
                          <br />
                        </p>
                    </div>
                  )}
                  <p
                    style={{ wordWrap: " break-word" }}
                    id={
                      window.innerWidth > 500
                        ? "remainingLaptopText"
                        : "mobileRemainText"
                    }
                  >
                   
                    
                    Enjoy!
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Spinner */}
          {/* second margin */}
          <fieldset
            className={`my-4 ${window.innerWidth > 770 ? "mx-4" : "mx-1"}`}
          >
            {isWideScreen ? (
              <div className="container my-5">
                <div className="d-flex justify-content-evenly">
                  {Array(5)
                    .fill()
                    .map((_, index) =>
                      manualHand[index]?.length >= 2 ? (
                        <img
                          style={{
                            cursor: "pointer",
                            maxWidth: "20%",
                            paddingLeft: "5px",
                          }}
                          id={"card" + (index + 1)}
                          src={CardImages[manualHand[index]]}
                          alt="Selected Card"
                          className="text-center"
                          onClick={() => handleCardClick(index)}
                        />
                      ) : (
                        <p
                          id={"card" + (index + 1)}
                          style={{
                            backgroundColor: "white",
                            cursor: "pointer",
                            border: "2px grey solid",
                            fontSize:
                              window.innerWidth > 1000
                                ? "1.6rem"
                                : window.innerWidth > 770
                                ? "1rem"
                                : window.innerWidth > 630
                                ? "0.75rem"
                                : "0.62rem",
                            paddingTop:
                              window.innerWidth > 1000
                                ? "6rem"
                                : window.innerWidth > 770
                                ? "4rem"
                                : window.innerWidth > 630
                                ? "3rem"
                                : "2.2rem",
                            marginLeft:"5px" ,
                          }}
                          className="text-center"
                          onClick={() => handleCardClick(index)}
                        >
                          Select Card
                        </p>
                      )
                    )}

                  <CustomizedDialogs
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onImageSelect={handleImageSelection}
                    // Pass the selected card ID to the modal
                  />
                </div>
                {/* suit of card */}
                <div
                  className={`my-4 row d-flex justify-content-evenly text-center`}
                >
                  {Array(5)
                    .fill()
                    .map((_, index) => (
                      <div key={index} className="col-2">
                        <select
                          id="cardSelect"
                          value={manualHand[index]?.charAt(0)}
                          onChange={(value) => {
                            handleSelection(index, value.target.value, 1);
                          }}
                        >
                          <option disabled selected value="">
                            {window.innerWidth > 1000 ? "Card Suit" : "Suit"}
                          </option>
                          {cardSuit.map((option) => (
                            <option
                              id="cardOption"
                              key={option.name}
                              value={option.value}
                            >
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))}
                </div>

                {/* Number of card */}
                <div
                  className={`my-4 row d-flex justify-content-evenly text-center`}
                >
                  {Array(5)
                    .fill()
                    .map((_, index) => (
                      <div key={index} className="col-2">
                        <select
                          id="cardSelect"
                          value={
                            manualHand[index]?.charAt(1) == 1
                              ? manualHand[index]?.slice(1)
                              : manualHand[index]?.charAt(1)
                          }
                          onChange={(value) => {
                            handleSelection(index, value.target.value, 2);
                          }}
                        >
                          <option disabled selected value="">
                            {window.innerWidth > 1000
                              ? "Card Number"
                              : window.innerWidth > 770
                              ? "Number"
                              : "No."}
                          </option>
                          {cardNumber.map((option) => (
                            <option
                              id="cardOption"
                              key={option.name}
                              value={option.value}
                            >
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div className="container my-5">
                {/* 2 Manual card */}
                <div className=" d-flex justify-content-evenly">
                  {Array(2)
                    .fill()
                    .map((_, index) =>
                      manualHand[index]?.length >= 2 ? (
                        <img
                          style={{ cursor: "pointer", maxWidth: "40%" }}
                          id="spinImage"
                          src={CardImages[manualHand[index]]}
                          alt="Selected Card"
                          className="col-6 text-center"
                          onClick={() => handleCardClick(index)}
                        />
                      ) : (
                        <p
                          id="spinImage"
                          style={{
                            backgroundColor: "white",
                            cursor: "pointer",
                            border: "2px grey solid",
                            fontSize:
                              window.innerWidth > 1000
                                ? "1.6rem"
                                : window.innerWidth > 770
                                ? "1rem"
                                : window.innerWidth > 630
                                ? "0.75rem"
                                : window.innerWidth > 330
                                ? "0.62rem"
                                : window.innerWidth > 300
                                ? "0.5rem"
                                : "0.4rem",
                            paddingTop:
                              window.innerWidth > 1000
                                ? "6rem"
                                : window.innerWidth > 770
                                ? "4rem"
                                : window.innerWidth > 630
                                ? "3rem"
                                : "2.2rem",
                            margin: 0,
                            maxWidth:"50%"
                          }}
                          className="col-6 text-center"
                          onClick={() => handleCardClick(index)}
                        >
                          Select Card
                        </p>
                      )
                    )}
                </div>
                {/* select Suit */}
                <div
                  className={`my-4 row d-flex justify-content-evenly text-center`}
                >
                  {Array(2)
                    .fill()
                    .map((_, index) => (
                      <div key={index} className="col-2">
                        <select
                          id="cardSelect"
                          onChange={(value) => {
                            handleSelection(index, value.target.value, 1);
                          }}
                        >
                          <option disabled selected value="">
                            {window.innerWidth > 330 ? "Card Suit" : "Suit"}
                          </option>
                          {cardSuit.map((option) => (
                            <option
                              id="cardOption"
                              key={option.name}
                              value={option.value}
                            >
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))}
                </div>
                {/* Select Number */}
                <div
                  className={`my-4 row d-flex justify-content-evenly text-center`}
                >
                  {Array(2)
                    .fill()
                    .map((_, index) => (
                      <div key={index} className="col-2">
                        <select
                          id="cardSelect"
                          onChange={(value) => {
                            handleSelection(index, value.target.value, 2);
                          }}
                        >
                          <option disabled selected value="">
                            {window.innerWidth > 410
                              ? "Card Number"
                              : window.innerWidth > 330
                              ? "Number"
                              : "No."}
                          </option>
                          {cardNumber.map((option) => (
                            <option
                              id="cardOption"
                              key={option.name}
                              value={option.value}
                            >
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))}
                </div>
                {/* 3 Manual card */}
                <div className="d-flex justify-content-evenly">
                  {Array(3)
                    .fill()
                    .map((_, index) =>
                      manualHand[index + 2]?.length >= 2 ? (
                        <img
                          style={{ cursor: "pointer", maxWidth: "33%" }}
                          id="spinImage"
                          src={CardImages[manualHand[index + 2]]}
                          alt="Selected Card"
                          className="col-6 text-center"
                          onClick={() => handleCardClick(index + 2)}
                        />
                      ) : (
                        <p
                          id="spinImage"
                          style={{
                            backgroundColor: "white",
                            cursor: "pointer",
                            border: "2px grey solid",
                            fontSize:
                              window.innerWidth > 1000
                                ? "1.6rem"
                                : window.innerWidth > 770
                                ? "1rem"
                                : window.innerWidth > 630
                                ? "0.75rem"
                                : window.innerWidth > 330
                                ? "0.62rem"
                                : window.innerWidth > 300
                                ? "0.5rem"
                                : "0.4rem",
                            paddingTop:
                              window.innerWidth > 1000
                                ? "6rem"
                                : window.innerWidth > 770
                                ? "4rem"
                                : window.innerWidth > 630
                                ? "3rem"
                                : "2.2rem",
                            margin: 0,
                            maxWidth:"38%"
                          }}
                          className="col-6 text-center"
                          onClick={() => handleCardClick(index + 2)}
                        >
                          Select Card
                        </p>
                      )
                    )}
                </div>
                {/* select Suit */}
                <div
                  className={`my-4 row d-flex justify-content-evenly text-center`}
                >
                  {Array(3)
                    .fill()
                    .map((_, index) => (
                      <div key={index + 2} className="col-2">
                        <select
                          id="cardSelect"
                          onChange={(value) => {
                            handleSelection(index + 2, value.target.value, 1);
                          }}
                        >
                          <option disabled selected value="">
                            {window.innerWidth > 330 ? "Card Suit" : "Suit"}
                          </option>
                          {cardSuit.map((option) => (
                            <option
                              id="cardOption"
                              key={option.name}
                              value={option.value}
                            >
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))}
                </div>
                {/* Select Number */}
                <div
                  className={`my-4 row d-flex justify-content-evenly text-center`}
                >
                  {Array(3)
                    .fill()
                    .map((_, index) => (
                      <div key={index + 2} className="col-2">
                        <select
                          id="cardSelect"
                          onChange={(value) => {
                            handleSelection(index + 2, value.target.value, 2);
                          }}
                        >
                          <option disabled selected value="">
                            {window.innerWidth > 410
                              ? "Card Number"
                              : window.innerWidth > 330
                              ? "Number"
                              : "No."}
                          </option>
                          {cardNumber.map((option) => (
                            <option
                              id="cardOption"
                              key={option.name}
                              value={option.value}
                            >
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))}
                </div>
                <CustomizedDialogs
                  open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  onImageSelect={handleImageSelection}
                  // Pass the selected card ID to the modal
                />
              </div>
            )}
          </fieldset>

          {/* Person Details */}
          {/* Third margin */}
          <fieldset
            className={`p-3 ${window.innerWidth > 770 ? "mx-4" : "mx-1"} `}
          >
            <form method="post">
              <div>
                <div style={{ textAlign: "center", wordSpacing: "10px" }}>
                  <p
                    style={{ fontSize: window.innerWidth > 500 ? "" : "19px" }}
                    className="text-center my-3"
                  >
                    <strong>
                      Enter Your Name and Email to See Your Custom Personality
                      Profile
                    </strong>
                  </p>
                  <div className="row">
                    <div
                      className={window.innerWidth > 430 ? "col-6" : "col-12"}
                    >
                      <i className="fs-1 bi bi-person-circle"></i>&nbsp;
                      <input
                        style={{
                          maxWidth: "100%",
                          borderRadius: 15,
                          borderColor: isEmptyName && "red",
                        }}
                        id="detailsInput"
                        className=" my-4 "
                        type="text"
                        onChange={(data) => {
                          setName(data.target.value);
                          setIsEmptyName(false);
                        }}
                        placeholder="First Name..."
                      />
                    </div>
                    <div
                      className={window.innerWidth > 430 ? "col-6" : "col-12"}
                    >
                      <i className="fs-1 bi bi-envelope-at-fill"></i>&nbsp;
                      <input
                        style={{
                          maxWidth: "100%",
                          borderRadius: 15,
                          borderColor: isEmptyEmail && "red",
                        }}
                        id="detailsInput"
                        className="my-4"
                        type="email"
                        onChange={(data) => {
                          if ((emailValidator(data.target.value)) || data.target.value=="test") {
                            setEmail(data.target.value);
                            setIsEmptyEmail(false);
                          } else {
                            setIsEmptyEmail(true);
                          }
                        }}
                        placeholder="Email Address..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </fieldset>

          {/* Footer */}
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <a onClick={goToPageTwo}>
              <img
                className="img-fluid my-3"
                src="images/done.png"
                alt="Next Page"
                width="450" // Set the desired width
                height="62" // Set the desired height
                style={{ maxWidth: "80%", cursor: "pointer" }}
              />
            </a>
          </div>
        </div>
        <p
          style={{
            textAlign: "center",
            fontSize: window.innerWidth > 320 ? "17px" : "13px",
            color: "grey",
            marginTop: "30px",
          }}
        >
          ©2015-2023 Stephen Shapiro | All Rights Reserved | &nbsp;
          <a
            target="_blank"
            style={{ color: "grey" }}
            href="https://stephenshapiro.com/legal-information/privacy-policy/"
          >
            Our Privacy Policy
          </a>
        </p>
      </div>
    </>
  );
};

export default HomeManuall;
