import React, { useEffect, useState, useRef } from "react";
import { CardImages } from "../Resources/CardImages";
import { SpinImages } from "../Resources/SpinImages";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [cardsInHand, setCardsInHand] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [holdStatus, setHoldStatus] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [currentImages, setCurrentImages] = useState([]);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [isEmptyName, setIsEmptyName] = useState(false);
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);

  // Array of image keys for your spinning animation in the desired order
  const imageKeys = ["spin3", "spin4", "spin5"];

  const triggerWebhook = async () => {
    var link, hand;
      link =
        "https://personalitypokergames.com/Result?keys=" +
        cardsInHand.toString() +
        "&name=" +
        name;
      hand = cardsInHand.toString();
    try {
      const response = await fetch(
        "https://personality-poker-42daff2399da.herokuapp.com/send-data",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Name: name,
            Email: email,
            Hand: hand,
            Link: link,
          }),
        }
      );
      if (response.ok) {
        console.log("Data sent to server successfully.");
      } else {
        console.error("Error sending data to server.");
      }
    } catch (error) {
      console.error("Error sending data to server:", error);
    }
  };

  useEffect(() => {
    let animationTimer;

    const startAnimation = () => {
      setIsAnimating(true); // Start animation
      for (let i = 0; i < 5; i++) {
        if (!holdStatus[i]) {
          const imgElement = document.getElementById(`card${i + 1}`);
          if (imgElement) {
            imgElement.src = SpinImages.spin4;
          }
        }
      }
      animationTimer = setTimeout(() => {
        setIsAnimating(false); // Stop animation after 3 seconds
        setCurrentImageIndex(0); // Reset to the first image
        randomSelector();
      }, 2000);
    };

    if (isAnimating) {
      startAnimation();
    }

    // Cleanup on unmount
    return () => {
      clearTimeout(animationTimer);
    };
  }, [isAnimating]);

  const handleButtonClick = () => {
    setIsAnimating(true); // Start animation when the button is clicked
  };

  // Modify randomSelector function to skip held cards
  const randomSelector = () => {
    const cardKeys = Object.keys(CardImages);
    for (let i = cardKeys.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [cardKeys[i], cardKeys[j]] = [cardKeys[j], cardKeys[i]];
    }

    const selectedCards = [];
    let selectedCardImages = [];

    // Access the image locations for the selected cards
    for (let i = 0; i < 5; i++) {
      const cardKey = cardKeys[i];

      if (!holdStatus[i] && CardImages.hasOwnProperty(cardKey)) {
        const cardImage = CardImages[cardKey];
        selectedCardImages[i] = cardImage;
        selectedCards[i] = cardKey;
      } else {
        selectedCards[i] = cardsInHand[i];
        // If the card is held, use the current displayed image
        selectedCardImages[i] = currentImages[i];
      }
    }
    setCardsInHand(selectedCards);
    setCurrentImages(selectedCardImages);

    for (let i = 0; i < selectedCardImages.length; i++) {
      const imgElement = document.getElementById(`card${i + 1}`);
      if (imgElement) {
        imgElement.src = selectedCardImages[i];
      }
    }
  };

  // Modify the button click event to toggle hold status and button class
  const toggleHold = (index) => {
    const updatedHoldStatus = [...holdStatus];
    updatedHoldStatus[index] = !updatedHoldStatus[index];
    setHoldStatus(updatedHoldStatus);
  };

  const navigate = useNavigate();

  const goToPageTwo = () => {
    if ((name && email) || (name==="test" && email==="test")) {
      triggerWebhook();
      const link =
          "https://personalitypokergames.com/Result?keys=" +
          cardsInHand.toString();
      
        navigate("/Result", {
          state: { currentImages, cardsInHand, link },
        });
    } else {
      if (!name) {
        setIsEmptyName(true);
      }
      if (!email) {
        setIsEmptyEmail(true);
      }
    }
  };

  const emailValidator = (email) => {
    // Regular expression for a valid email address
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Use the test method to check if the email matches the regex
    return emailRegex.test(email);
  };

  useEffect(() => {
    randomSelector();
  }, []);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 500);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 500);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  return (
    <>
      <div className={isWideScreen ? "container" : ""}>
        {/* Logo */}
        <div className="container my-4">
          <a target="_blank" href="https://personalitypokergames.com">
            <img
              className="my-2 mx-2"
              style={{ maxWidth: "80%", height: "auto" }}
              src="./images/logo.png"
              alt="poker-logo"
            />
          </a>
        </div>
        {/* Accordion */}
        <div
          className="fluid-container"
          style={{
            // Sets color inside div
            background: "white",
            border: "3px solid rgba(128, 128, 128, 0.477)",
            borderRadius: "7px",
            boxShadow: "5px 5px 10px 8px grey",
          }}
        >
          <div>
            <ul
              id="navLaptop"
              style={{
                margin: 0,
                padding: 0,
                backgroundColor: "#bdc5e0b3",
              }}
              className="row d-flex justify-content-between"
            >
              <li
                className="col-6"
                style={{
                  borderRadius: "0px 15px 15px 0px",
                  backgroundColor: "white",
                }}
              >
                <span>
                  <img
                    style={{ width: window.innerWidth > 500 ? "45px" : "25px" }}
                    src="images/icons8-1-70.png"
                  />
                </span>
                <span style={{ fontFamily: "'Times New Roman', Times, serif" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PLAY GAME
                </span>
              </li>
              <li
                className="col-6"
                style={{ fontFamily: "fantasy", color: "#909094" }}
              >
                <span>
                  <img
                    style={{ width: window.innerWidth > 500 ? "45px" : "25px" }}
                    src="images/icons8-2-80.png"
                  />
                </span>
                <span style={{ fontFamily: "'Times New Roman', Times, serif" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SEE YOUR RESULTS
                </span>
              </li>
              {/* <li className="col-4" style={{backgroundColor:"#bdc5e0b3",color: "#909094"}}>
              <span>
                <img style={{width:"22px"}} src="images/icons8-3-80.png" />
              </span>
              <span> SHARE WITH FRIENDS</span>
            </li> */}
            </ul>
            <hr />
          </div>
          {/* first margin */}
          <div
            className={`accordion ${
              window.innerWidth > 770 ? "m-4" : "my-4 mx-1"
            }`}
          >
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  style={{ backgroundColor: "white" }}
                  className="accordion-button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                >
                  <strong>
                    <h5 id={window.innerWidth > 550 ? "" : "mobileHeading"}>
                      PERSONALITY POKER VIDEO GAME
                    </h5>
                  </strong>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body">
                  {isWideScreen ? (
                    <div className="d-flex">
                      <p id="accordionText">
                        Welcome to the <strong>Personality Poker®</strong>{" "}
                        online video game.
                        <br />
                        <br />
                        This fun and simple personality assessment will help you
                        identify your personality style and other attributes.
                        <br />
                        <br />
                        Below you will find a Las Vegas style video poker
                        machine. Like the Vegas machines, you are dealt 5 random
                        cards. You get to hold cards that you like and swap out
                        the others. Unlike the gambling machines, with our video
                        poker game, you get to keep swapping cards until you get
                        the best hand.
                        <strong>
                          The best hand is one where all five words accurately
                          describe your personality.
                        </strong>
                        <br />
                      </p>
                      <img
                        id="accordionImage"
                        src="./images/Poker.png"
                        alt="Poker-bookwaccordionImagecardscover"
                      />
                    </div>
                  ) : (
                    <div className="row">
                      <p id="mobileText" className="col-8">
                        Welcome to the <strong>Personality Poker®</strong>{" "}
                        online video game.
                      </p>
                      <img
                        id="mobileImage"
                        className="col-4"
                        src="./images/Poker.png"
                        alt="Poker-bookwcardscover"
                      />
                      <p id="mobileRemainText">
                        This fun and simple personality assessment will help you
                        identify your personality style and other attributes.
                        <br />
                        <br />
                        <p id="mobileRemainText">
                          Below you will find a Las Vegas style video poker
                          machine. Like the Vegas machines, you are dealt 5
                          random cards. You get to hold cards that you like and
                          swap out the others. Unlike the gambling machines,
                          with our video poker game, you get to keep swapping
                          cards until you get the best hand.
                          <strong>
                            The best hand is one where all five words accurately
                            describe your personality.
                          </strong>
                          <br />
                        </p>
                      </p>
                    </div>
                  )}
                  <p
                    style={{ wordWrap: " break-word" }}
                    id={
                      window.innerWidth > 500
                        ? "remainingLaptopText"
                        : "mobileRemainText"
                    }
                  >
                    <h4>
                      <strong>Approach:</strong>
                    </h4>
                    <ul>
                      <li>
                        Spin the wheels to reveal 5 cards with personality
                        traits.
                      </li>
                      <li>
                        "Hold" the cards that are like you. That is, the word
                        describes your personality.
                      </li>
                      <li>
                        Keep spinning (press "SPIN") until you get a hand where
                        all 5 words describe you.
                      </li>
                      <li>
                        Ideally your hand will have one of the 2, 3, or 4 cards.
                        These describe the unproductive side-effects of your
                        "strong suit."
                      </li>
                      <li>
                        When you have a hand with 5 words that describe you,
                        press the "DONE" button to get your results.
                      </li>
                    </ul>
                    Enjoy!
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Spinner */}
          {/* second margin */}
          <fieldset
            className={`my-4 ${window.innerWidth > 770 ? "mx-4" : "mx-1"}`}
          >
            <div className="container my-5">
              <div className={`d-flex justify-content-evenly`}>
                <img
                  style={{ paddingLeft: "5px", maxWidth: "20%" }}
                  id="card1"
                  className="col-2"
                  src={
                    holdStatus[0] || !isAnimating
                      ? currentImages[0]
                      : SpinImages[imageKeys[currentImageIndex]]
                  }
                  alt="card1"
                />
                <img
                  style={{ paddingLeft: "5px", maxWidth: "20%" }}
                  id="card2"
                  className="col-2"
                  src={
                    holdStatus[1] || !isAnimating
                      ? currentImages[1]
                      : SpinImages[imageKeys[currentImageIndex]]
                  }
                  alt="card2"
                />
                <img
                  style={{ paddingLeft: "5px", maxWidth: "20%" }}
                  id="card3"
                  className="col-2"
                  src={
                    holdStatus[2] || !isAnimating
                      ? currentImages[2]
                      : SpinImages[imageKeys[currentImageIndex]]
                  }
                  alt="card3"
                />
                <img
                  style={{ paddingLeft: "5px", maxWidth: "20%" }}
                  id="card4"
                  className="col-2"
                  src={
                    holdStatus[3] || !isAnimating
                      ? currentImages[3]
                      : SpinImages[imageKeys[currentImageIndex]]
                  }
                  alt="card4"
                />
                <img
                  style={{ paddingLeft: "5px", maxWidth: "20%" }}
                  id="card5"
                  className="col-2"
                  src={
                    holdStatus[4] || !isAnimating
                      ? currentImages[4]
                      : SpinImages[imageKeys[currentImageIndex]]
                  }
                  alt="card5"
                />
              </div>
              <div
                className={`my-4 row d-flex justify-content-evenly text-center`}
              >
                <div className="col-2">
                  <button
                    id="hold"
                    className={` ${
                      holdStatus[0] ? "bg-dark text-light" : "text-muted"
                    }`}
                    onClick={() => toggleHold(0)}
                  >
                    Hold
                  </button>
                </div>
                <div className="col-2">
                  <button
                    id="hold"
                    className={` ${
                      holdStatus[1] ? "bg-dark text-light" : "text-muted"
                    }`}
                    onClick={() => toggleHold(1)}
                  >
                    Hold
                  </button>
                </div>
                <div className="col-2">
                  <button
                    id="hold"
                    className={`${
                      holdStatus[2] ? "bg-dark text-light" : "text-muted"
                    }`}
                    onClick={() => toggleHold(2)}
                  >
                    Hold
                  </button>
                </div>
                <div className="col-2">
                  <button
                    id="hold"
                    className={` ${
                      holdStatus[3] ? "bg-dark text-light" : "text-muted"
                    }`}
                    onClick={() => toggleHold(3)}
                  >
                    Hold
                  </button>
                </div>
                <div
                  style={{
                    marginRight: window.innerWidth > 430 ? "0px" : "10px",
                  }}
                  className="col-2"
                >
                  <button
                    id="hold"
                    className={`${
                      holdStatus[4] ? "bg-dark text-light" : "text-muted"
                    }`}
                    onClick={() => toggleHold(4)}
                  >
                    Hold
                  </button>
                </div>
              </div>
              <div className="text-center">
                <a
                  type="button"
                  onClick={() => {
                    handleButtonClick();
                  }}
                >
                  <img id="spinBtn" src="./images/spin.png" alt="Poker-spin" />
                </a>
              </div>
            </div>
          </fieldset>

          {/* Person Details */}
          {/* Third margin */}
          <fieldset
            className={`p-3 ${window.innerWidth > 770 ? "mx-4" : "mx-1"} `}
          >
            <form method="post">
              <div>
                <div style={{ textAlign: "center", wordSpacing: "10px" }}>
                  <p
                    style={{ fontSize: window.innerWidth > 500 ? "" : "19px" }}
                    className="text-center my-3"
                  >
                    <strong>
                      Enter Your Name and Email to See Your Custom Personality
                      Profile
                    </strong>
                  </p>
                  <div className="row">
                    <div
                      className={window.innerWidth > 430 ? "col-6" : "col-12"}
                    >
                      <i className="fs-1 bi bi-person-circle"></i>&nbsp;
                      <input
                        style={{
                          maxWidth: "100%",
                          borderRadius: 15,
                          borderColor: isEmptyName && "red",
                        }}
                        id="detailsInput"
                        className=" my-4 "
                        type="text"
                        onChange={(data) => {
                          setName(data.target.value);
                          setIsEmptyName(false);
                        }}
                        placeholder="First Name..."
                      />
                    </div>
                    <div
                      className={window.innerWidth > 430 ? "col-6" : "col-12"}
                    >
                      <i className="fs-1 bi bi-envelope-at-fill"></i>&nbsp;
                      <input
                        style={{
                          maxWidth: "100%",
                          borderRadius: 15,
                          borderColor: isEmptyEmail && "red",
                        }}
                        id="detailsInput"
                        className="my-4"
                        type="email"
                        onChange={(data) => {
                          if ((emailValidator(data.target.value)) || data.target.value==="test") {
                            setEmail(data.target.value);
                            setIsEmptyEmail(false);
                          } else {
                            setIsEmptyEmail(true);
                          }
                        }}
                        placeholder="Email Address..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </fieldset>

          {/* Footer */}
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <a onClick={goToPageTwo}>
              <img
                className="img-fluid my-3"
                src="images/done.png"
                alt="Next Page"
                width="450" // Set the desired width
                height="62" // Set the desired height
                style={{ maxWidth: "80%", cursor: "pointer" }}
              />
            </a>
          </div>
        </div>
        <p
          style={{
            textAlign: "center",
            fontSize: window.innerWidth > 320 ? "17px" : "13px",
            color: "grey",
            marginTop: "30px",
          }}
        >
          ©2015-2023 Stephen Shapiro | All Rights Reserved | &nbsp;
          <a
            target="_blank"
            style={{ color: "grey" }}
            href="https://stephenshapiro.com/legal-information/privacy-policy/"
          >
            Our Privacy Policy
          </a>
        </p>
      </div>
    </>
  );
};

export default Home;
