import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getColor, getHighOrLow, getIntroOrExtro, getOpposite, getPrimaryStrongSuit, getPrimarySummary, getShortSummary, getSecondary } from "../helper/Section";
import { CardImages } from "../Resources/CardImages";
import { Helmet } from "react-helmet";
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";


const Result = () => {

  
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  
  const { state } = useLocation();
  // Define state variables for currentImages and cardsInHand
  const [currentImages, setCurrentImages] = useState([]);
  const [cardsInHand, setCardsInHand] = useState([]);
  const [link, setLink] = useState("https://personalitypokergames.com")

  useEffect(() => {
    // Update currentImages and cardsInHand when state changes
    if (state) {
      if (!state?.manualHand) {
        setCurrentImages(state.currentImages || []);
        setCardsInHand(state.cardsInHand || []);
      } else {
        setCardsInHand(state.manualHand || []);
        const manualHand = [...state.manualHand];
        setCurrentImages([CardImages[manualHand[0]], CardImages[manualHand[1]], CardImages[manualHand[2]], CardImages[manualHand[3]], CardImages[manualHand[4]]] || [])
      }
      if (state.link) {
        setLink(state.link);
      }
    }
  }, [state]);

  const [name, setName] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlKeys = params.get("keys");
    const urlName = params.get("name");

    if (urlKeys) {
      setCardsInHand(urlKeys.split(","));
      const images = [];
      urlKeys.split(",").forEach(key => {
        images.push(CardImages[key]);
      });
      setCurrentImages(images);
      if (urlName) {
        setName(urlName);
        setLink("https://personalitypokergames.com/Result?keys=" + urlKeys.split(","));
      } else {
        setName("newUser");
      }
    }
  }, []);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 500);

  const brainSection = () => {
    let blackKeys = 0;
    let redKeys = 0;
  
    for (let i = 0; i < cardsInHand.length; i++) {
      if (cardsInHand[i][0] === 'c' || cardsInHand[i][0] === 's') {
        blackKeys++;
      } else if (cardsInHand[i][0] === 'd' || cardsInHand[i][0] === 'h') {
        redKeys++;
      }
    }

    let type;

    if (blackKeys === 3) {
      type = '3black';
    } else if (blackKeys === 4) {
      type = '4black';
    } else if (blackKeys === 5) {
      type = '5black';
    } else if (redKeys === 3) {
      type = '3red';
    } else if (redKeys === 4) {
      type = '4red';
    } else if (redKeys === 5) {
      type = '5red';
    }

    const brain = getColor(type);

    return (
      <>
      {window.innerWidth > 500 ? (
      <div className="d-flex align-items-center">
        <div style={{ flex: "12%" }}>
          <img src={brain?.img} alt="Brain" />
        </div>
        <div style={{ flex: "88%" }}>
          <p style={{maxWidth:"100%"}} id="p2Text">{brain?.text}</p>
        </div>
      </div>
    ) : (
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex justify-content-center">
          <img  src={brain?.img} alt="Brain" style={{width:"92px", height:"128", maxWidth: "100%" }} />
        </div>
        <p style={{maxWidth:"100%"}} id="p2Text">{brain?.text}</p>
      </div>
    )}
      </>
    )
  }

  const getPrimary = () => {
    let diamonds = 0;
    let hearts = 0;
    let spades = 0;
    let clubs = 0;
  
    for (let i = 0; i < cardsInHand.length; i++) {
      if (cardsInHand[i][0] === 'c') {
        clubs++;
      } else if (cardsInHand[i][0] === 's') {
        spades++;
      } else if (cardsInHand[i][0] === 'h') {
        hearts++;
      } else if (cardsInHand[i][0] === 'd') {
        diamonds++;
      }
    }

    let primary;

    if (clubs>=3){
      primary = "clubs"
    } else if (spades>=3){
      primary = "spades"
    } else if (hearts>=3){
      primary = "hearts"
    } else if (diamonds>=3){
      primary = "diamonds"
    } else {
      if (clubs === 2 && spades === 1 && hearts === 1 && diamonds === 1) {
        primary = "clubs";
      } else if (clubs === 1 && spades === 2 && hearts === 1 && diamonds === 1) {
        primary = "spades";
      } else if (clubs === 1 && spades === 1 && hearts === 2 && diamonds === 1) {
        primary = "hearts";
      } else if (clubs === 1 && spades === 1 && hearts === 1 && diamonds === 2) {
        primary = "diamonds";
      } else {
        if (clubs===0){
          primary = "diamonds"
        } else if (spades===0){
          primary = "hearts"
        } else if (hearts===0){
          primary = "spades"
        } else if (diamonds===0){
          primary = "clubs"
        }
      }
    }

    return primary;
  };

  const primarySection = () => {
    const primary = getPrimaryStrongSuit(getPrimary());

    return (
      <>
      <span>{primary.text}</span>
      </>
    );
  }

  const highLowStrongSuitSection = () => {
    const primary = getPrimary();
    let high = 0;
    let low = 0;
    let primaryCount = 0;

    for (let i = 0; i < cardsInHand.length; i++) {
      if (cardsInHand[i][0] === primary[0]) {
        primaryCount++;
        if (cardsInHand[i][1] === '5' || cardsInHand[i][1] === '6' ||
            cardsInHand[i][1] === '7' || cardsInHand[i][1] === '8' ||
            cardsInHand[i][1] === '9') {
          low++;
        } else if (cardsInHand[i][1] == '1' || cardsInHand[i][1] === 'j' ||
        cardsInHand[i][1] === 'q' || cardsInHand[i][1] === 'k' ||
        cardsInHand[i][1] === 'a') {
          high++;
        }
      } 
    }

    if (primaryCount>1) {
      let highLowSuit;

      if (primary === "spades" ) {
        if (low > (primaryCount/2)) {
          highLowSuit = "lows";
        } else if (high > (primaryCount/2)) {
          highLowSuit = "highs";
        }
      } else if (primary === "hearts" ) {
        if (low > (primaryCount/2)) {
          highLowSuit = "lowh";
        } else if (high > (primaryCount/2)) {
          highLowSuit = "highh";
        }
      } else if (primary === "clubs" ) {
        if (low > (primaryCount/2)) {
          highLowSuit = "lowc";
        } else if (high > (primaryCount/2)) {
          highLowSuit = "highc";
        }
      } else if (primary === "diamonds" ) {
        if (low > (primaryCount/2)) {
          highLowSuit = "lowd";
        } else if (high > (primaryCount/2)) {
          highLowSuit = "highd";
        }
      }

      if (highLowSuit) {
        const strongSuit = getHighOrLow(highLowSuit);
        return (
          <>
          {strongSuit.text}</>
        );
      }
    }
  }

  const intoExtroSection = () => {
    let high = 0;
    let low = 0;

    for (let i = 0; i < cardsInHand.length; i++) {
      if (cardsInHand[i][1] === '5' || cardsInHand[i][1] === '6' ||
          cardsInHand[i][1] === '7' || cardsInHand[i][1] === '8' ||
          cardsInHand[i][1] === '9') {
        low++;
      } else if (cardsInHand[i][1] === '1' || cardsInHand[i][1] === 'j' ||
      cardsInHand[i][1] === 'q' || cardsInHand[i][1] === 'k' ||
      cardsInHand[i][1] === 'a') {
        high++;
      }
    }

    let introOrExtro;

    if (high>=3) {
      introOrExtro = "Extrovert";
    } else if (low>=3) {
      introOrExtro = "Introvert";
    }

    if (introOrExtro) {
      const introExtroSection = getIntroOrExtro(introOrExtro);
      return (
        <>
        {introExtroSection.text}</>
      );
    }
  }

  const secondarySection = () => {
    const primary = getPrimary();
    let diamonds = 0;
    let hearts = 0;
    let spades = 0;
    let clubs = 0;
  
    for (let i = 0; i < cardsInHand.length; i++) {
      if (cardsInHand[i][0] === 'c') {
        clubs++;
      } else if (cardsInHand[i][0] === 's') {
        spades++;
      } else if (cardsInHand[i][0] === 'h') {
        hearts++;
      } else if (cardsInHand[i][0] === 'd') {
        diamonds++;
      }
    }

    let secondary = [];

    if (clubs>0 && primary !== "clubs" && primary !== "diamonds"){
      secondary.push("clubs");
    } 
    if (spades>0 && primary !== "spades" && primary !== "hearts"){
      secondary.push("spades");
    } 
    if (hearts>0 && primary !== "spades" && primary !== "hearts"){
      secondary.push("hearts")
    } 
    if (diamonds>0 && primary !== "clubs" && primary !== "diamonds"){
      secondary.push("diamonds")
    }


    if (secondary.length > 0) {
      const renderedSections = secondary.map((sec, index) => {
        const section = getSecondary(sec);
        return (
          <React.Fragment key={index}>
            {section.text}
          </React.Fragment>
        );
      });

      return (
        <>
        <h2 style={{textAlign:"initial"}} className="text-left my-4">Secondary Suit Analysis</h2>
        <p id="p2Text">In addition to your primary strong suit, it appears that you have one or more secondaries. This means that you are hard to pigeon hole into one category. You call upon your secondary strengths whenever you want.</p>
        {renderedSections}
        </>);
    }
    
  }

  const oppositeSection = () => {
    const primary = getPrimary();

    let diamonds = 0;
    let hearts = 0;
    let spades = 0;
    let clubs = 0;
  
    for (let i = 0; i < cardsInHand.length; i++) {
      if (cardsInHand[i][0] === 'c') {
        clubs++;
      } else if (cardsInHand[i][0] === 's') {
        spades++;
      } else if (cardsInHand[i][0] === 'h') {
        hearts++;
      } else if (cardsInHand[i][0] === 'd') {
        diamonds++;
      }
    }

    let opposite;
    if (primary === "hearts" && spades===0) {
      opposite = "spades";
    } else if (primary === "spades" && hearts===0) {
      opposite = "hearts";
    } else if (primary === "clubs" && diamonds===0) {
      opposite = "diamonds";
    } else if (primary === "diamonds" && clubs===0) {
      opposite = "clubs"
    }

    if (opposite){
      const oppositeSuit = getOpposite(opposite);
      return (
        <>
        <h2 className="text-left my-4">Opposite Suit</h2>
        {oppositeSuit.text}</>
      )
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 500);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const navigate = useNavigate();

  const goToPageOne = () => {
      navigate('/');
  };

  return (
    <>
    {name && <Helmet>
        <meta
          property="og:title"
          content="I just played Personality Poker®. Here's my hand and here's what it means."
        />
        <meta
          property="og:description"
          content="Learn more about my hand and play the game yourself."
        />
        <meta property="og:image" content="../images/personalitypoker.jpg" />
        <meta property="og:url" content={link} />
        {/* Add other meta tags as needed */}
      </Helmet>}
      <div className={isWideScreen ? "container" : ""}>
      
       {/* Logo */}
       <div className="container my-4">
        <a href="https://personalitypokergames.com">
          <img
            className="my-2 mx-2"
            style={{ maxWidth: "80%", height: "auto" }}
            src="./images/logo.png"
            alt="poker-logo"
          />
        </a>
      </div>
      {/* Accordion */}
      <div
        className="fluid-container"
        style={{
          // Sets color inside div
          background: "white",
          border: "3px solid rgba(128, 128, 128, 0.477)",
          borderRadius: "7px",
          boxShadow:"5px 5px 10px 8px grey"
        }}
      >
         <div>
          <ul id="navLaptop" style={{ margin: 0, padding: 0, backgroundColor:"#bdc5e0b3" }} className="row d-flex justify-content-between">
            <li onClick={goToPageOne} className="col-6" style={{color: "#909094", cursor: "pointer"}}>
              <span>
                <img style={{ width:window.innerWidth>500? "45px":"25px" }} src="images/icons8-1-80.png" />
              </span>
              <span style={{fontFamily:"'Times New Roman', Times, serif"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PLAY GAME</span>
            </li>
            <li className="col-6" style={{backgroundColor: "white", borderRadius: "15px 0px 0px 15px"}}>
              <span>
                <img style={{ width:window.innerWidth>500? "45px":"25px" }} src="images/icons8-2-70.png" />
              </span>
              <span style={{fontFamily:"'Times New Roman', Times, serif"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SEE YOUR RESULTS</span>
            </li>
            {/* <li className="col-4" style={{backgroundColor:"#bdc5e0b3",color: "#909094"}}>
              <span>
                <img style={{width:"22px"}} src="images/icons8-3-80.png" />
              </span>
              <span style={{fontFamily:"'Times New Roman', Times, serif"}}> SHARE WITH FRIENDS</span>
            </li> */}
          </ul>
          <hr/>
        </div>
        <h1 style={{ textAlign: "center", paddingTop: "2rem" }}>
          Here's Your Poker Hand
        </h1>
        <p className="mx-3 my-4" id="p2Text"style={{ textAlign: "center" }}>
          <em>
            Now let's see what it means for helping you get what you want in
            relationships and at work
          </em>
          <br />
          <br />
          <strong>Congratulations!</strong> Here are your customized results.
        </p>
        <fieldset className="m-4">
        <div className="container my-5">
      <div className={`${window.innerWidth>500? "":"row"} d-flex justify-content-evenly`}>
        
        {Array.from({ length: 5 }).map((_, index) => (
          <img
            key={index}
            id={`spinImage`}
            className=""
            src={currentImages[index] || "SPIN-logo+(2).png"}
            alt={`card${index + 1}`}
            style={{ margin: "10px 0px 10px 0px",paddingLeft:window.innerWidth>770? "5px":"2px" }}
          />
        ))}
      </div>
      {name !== "newUser" ? <div style={{width:window.innerWidth>770? "50%":window.innerWidth>500? "80%":"90%",backgroundColor:"#495057",borderRadius:"7px",margin:window.innerWidth>770? "4% 30% 2% 25%":window.innerWidth>500? "4% 10% 2% 10%":"4% 0% 2% 5%",padding:"20px 0px 20px 0px", boxShadow: "10px 10px"}}>
      <h3 style={{color:"white" ,textAlign:"center",marginBottom:"30px",fontFamily:"cursive",textDecoration:"underline",fontSize:window.innerWidth>320? "":"15px"}}>Share with Friends</h3>
      <div style={{maxWidth:"100%"}}  className="d-flex justify-content-evenly">
      <FacebookShareButton url={link} 
      >
        <FacebookIcon size={`${window.innerWidth>770? "50":window.innerWidth>330? "40":window.innerWidth>270? "30":"20"}`} round={true} />
      </FacebookShareButton>
      <TwitterShareButton url={link} 
        title={`I just played Personality Poker®. Here's my hand and here's what it means.\n\nPrimary Suit: \n` + getShortSummary(getPrimary())+ "\n\nLearn more about my hand and play the game yourself." + "\n\n"}
      >
        <TwitterIcon size={`${window.innerWidth>770? "50":window.innerWidth>330? "40":window.innerWidth>270? "30":"20"}`} round={true}/>
      </TwitterShareButton>

      <WhatsappShareButton url={link}
      title={`I just played Personality Poker®. Here's my hand and here's what it means.\n\nPrimary Suit: \n` + getPrimarySummary(getPrimary()) + "\n\nLearn more about my hand and play the game yourself." +"\n\n"}
      >
        <WhatsappIcon size={`${window.innerWidth>770? "50":window.innerWidth>330? "40":window.innerWidth>270? "30":"20"}`} round={true}/>
      </WhatsappShareButton>
      <EmailShareButton url={link}
      subject="Learn more about my hand and play the game yourself."
      body={`I just played Personality Poker®. Here's my hand and here's what it means.\n\nPrimary Suit: \n` + getPrimarySummary(getPrimary()) + "\n\n"}
      >
        <EmailIcon size={`${window.innerWidth>770? "50":window.innerWidth>330? "40":window.innerWidth>270? "30":"20"}`} round={true}/>
      </EmailShareButton>
      <LinkedinShareButton url={link}
      title="Learn more about my hand and play the game yourself."
      summary={`I just played Personality Poker®. Here's my hand and here's what it means.\n\nPrimary Suit: \n` + getPrimarySummary(getPrimary()) + "\n\n"}
      >
        <LinkedinIcon size={`${window.innerWidth>770? "50":window.innerWidth>330? "40":window.innerWidth>270? "30":"20"}`} round={true}/>
      </LinkedinShareButton>
    </div>
    </div>
    :
    <div className="d-flex justify-content-center" style={{marginTop: 30}}><a className="bn39" href="/"><span className="bn39span">PLAY THE GAME YOURSELF!!</span></a></div>}
    
    </div>
      {/* Link generating by Name area  */}


  {/* <div
  style={{
    backgroundColor: "white",
    borderRadius: "5px",
  }}
  className="row d-flex justify-content-evenly container my-5 py-4 mx-0"
>
  <div className="col-12 col-md-2">
    <img src="./images/Chat 2.png" alt="Chat Icon" />
  </div>
  <div className="text-start col-12 col-md-10 d-flex flex-wrap align-items-center">
    <h5>Save your hand to share with your friends!</h5>
    <p style={{ maxWidth: "100%" }}>
      Enter your name below to create your own address to share results with
      your friends.
      <br />
      <span className="link-text" style={{ overflowWrap: "break-word" }}>
        http://personalitypokergame.com/myhand/
      </span>
      <span style={{ flex: "1" }}>
        <input
          style={{
            maxWidth: "100%",
            boxSizing: "border-box",
            width: "auto",
          }}
        />
      </span>
      <img
        style={{ maxWidth: "100%" }}
        width="170rem"
        src="./images/name.png"
        alt="Name Icon"
      />
    </p>
  </div>
</div> */}


        </fieldset>
        <h2 style={{ marginTop: "30px", textAlign: "center" }}>
          How To Read Your Hand
        </h2>
        <div  style={{
      backgroundColor: "white",
      borderRadius: "5px",
      overflowWrap: "break-word", // This property prevents text from overflowing its container
    }} className="text-justify my-5 mx-4">
          <p id="p2Text">
            <b>
              Before getting to your customized results (below), here's a little
              background...
            </b>
          </p>
          <p id="p2Text">
            In Personality Poker, the <b>suits</b>,&nbsp;
            <font color="#FF0000">
              <b>colors</b>
            </font>
            &nbsp; and <b>numbers</b> all have meaning.
          </p>
          <p id="p2Text">
            The suit represents your primary style - your "<b>strong suit</b>."
            This will give you a sense of your strengths and weaknesses.
          </p>
          <p id="p2Text">
            In addition to your strong suit, some people have one or more
            <b> secondary styles</b>. These are the styles that are additional
            strengths that can be called upon when needed.
          </p>
          <p id="p2Text">
            You also have an <b>opposite</b> style. This describes the traits
            that are different than and complementary to your strengths. For
            example, if you are spontaneous, your opposite style would most
            likely be planning oriented.
          </p>
          <p id="p2Text">
            The <b>colors</b> are also useful to help you determine your{" "}
            <b>thinking style</b>. In general, there are two primary thinking
            styles: Rational/Analytical (sometimes referred to as "left
            brained") and Relational/Creative (often referred to as "right
            brained").
          </p>
          <p id="p2Text">
            The <b>numbers</b> indicate your{" "}
            <b>preferred style for generating energy</b>: either more
            solitary/individualistic in nature (often referred to as
            introverted) or more externally/experientially focused (often
            referred to as extroverted).
          </p>
          <p id="p2Text">
            This online version is intended for entertainment purposes and is
            not designed to replace playing with decks of Personality Poker
            cards. The results here are only representative. The greatest level
            of accuracy is achieved when playing Personality Poker with friends,
            family, and colleagues.
          </p>
          <p id="p2Text">
            Now that you have the high level explanation, let's dig into you and
            your style...
          </p>
        </div>
        <h2 style={{ marginTop: "30px", textAlign: "center" }}>
          What Your Hand Means
        </h2>
        {/* The part where personality details will come */}
        <div style={{marginBottom:"100px"}} className="mx-4">
          <h2 className="text-left my-4">Color (Black/Left and Red/Right)</h2>
          {brainSection()}
          <h2 className="text-left my-4">Primary Strong Suit</h2>
          {primarySection()}
          {/* <h2 className="text-left my-4">High Low Strong Suit Section</h2> */}
          <p id="p2Text">{highLowStrongSuitSection()}</p>
          {/* <h2 className="text-left my-4">Intro or Extro Section</h2> */}
          <p id="p2Text">{intoExtroSection()}</p>
          {/* <h2 className="text-left my-4">Secondary Suit Analysis</h2> */}
          <p id="p2Text">{secondarySection()}</p>
          {/* <h2 className="text-left my-4">Opposite Suit</h2> */}
          <p id="p2Text">{oppositeSection()}</p>
        </div>
        {/* <div>
          <h4 id="p2Text"
            style={{
              color: "red",
              fontWeight: "700",
              margin: "15px",
              textAlign:"justify"
            }}
          >
            Click the button below to receive a 6-part email series on using
            your Diamond personality to get what you want in relationships and
            at work.
          </h4>
          <img id="p2Image"
            src="./images/Diamond.png"
            align="center"
          />
        </div> */}
        <h2 style={{marginTop:"30px", textAlign:"center"}}>
          Thanks for Playing!
        </h2>
        <p id="p2Text" style={{ textAlign: "center" }}>
          <em>
            If you liked this, you'd really enjoy the Personality Poker card
            game
          </em>
        </p>
        <br />
        <p id="p2Text" style={{
          marginBottom:"50px",
      backgroundColor: "white",
      borderRadius: "5px",
      overflowWrap: "break-word", // This property prevents text from overflowing its container
    }}  className="text-justify mx-4"  >
          <img
            id="p2EndImage"
            src="./images/cards.jpg"
            align="left"
          />
          Thank you for taking the time to play our online Personality Poker
          video game. We hope you had fun.
          <br/><br/>
          Remember, this version is for entertainment purposes only. The best
          way to determine your personality profile and truly understand other
          people's personalities is the full card game. In the full game, you
          have the chance to pick cards out for your friends, and you'll
          interact with each other about your personality quirks. Grab your copy
          of the game by visiting this page: 
          <a target="_blank" href="https://stephenshapiro.com/personality-poker/">
            
            <> </>Get Your Personality Poker Decks
          </a></p>
      </div>
      <p
        style={{
          textAlign: "center",
          fontSize: window.innerWidth>320? "17px":"13px",
          color: "grey",
          marginTop: "30px",
        }}
      >
        ©2015-2023 Stephen Shapiro | All Rights Reserved |
        &nbsp;<a
          target="_blank"
          style={{ color: "grey" }}
          href="https://stephenshapiro.com/legal-information/privacy-policy/"
        >
          Our Privacy Policy
        </a>
      </p>
      </div>
    </>
  );
};

export default Result;
