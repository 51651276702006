import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Component/Home';
import Result from './Component/Result';
import HomeManuall from './Component/HomeManuall';

function App() {
  return (
    <Router>
      <>
        <Routes>
          <Route path='/' element={
          <Home/>
           } />
           <Route path='/7Bx9A1vCp2K5' element={
          <HomeManuall/>
           } />
          <Route  path='Result' element={
          <Result/>
          }/>
          <Route path='Result/:keys' element={<Result />} />
        </Routes>
      </>
    </Router>
  );
}

export default App;
