export const CardImages = {
    c2: require("../images/2c.gif"),
    d2: require("../images/2d.gif"),
    h2: require("../images/2h.gif"),
    s2: require("../images/2s.gif"),
    c3: require("../images/3c.gif"),
    d3: require("../images/3d.gif"),
    h3: require("../images/3h.gif"),
    s3: require("../images/3s.gif"),
    c4: require("../images/4c.gif"),
    d4: require("../images/4d.gif"),
    h4: require("../images/4h.gif"),
    s4: require("../images/4s.gif"),
    c5: require("../images/5c.gif"),
    d5: require("../images/5d.gif"),
    h5: require("../images/5h.gif"),
    s5: require("../images/5s.gif"),
    c6: require("../images/6c.gif"),
    d6: require("../images/6d.gif"),
    h6: require("../images/6h.gif"),
    s6: require("../images/6s.gif"),
    c7: require("../images/7c.gif"),
    d7: require("../images/7d.gif"),
    h7: require("../images/7h.gif"),
    s7: require("../images/7s.gif"),
    c8: require("../images/8c.gif"),
    d8: require("../images/8d.gif"),
    h8: require("../images/8h.gif"),
    s8: require("../images/8s.gif"),
    c9: require("../images/9c.gif"),
    d9: require("../images/9d.gif"),
    h9: require("../images/9h.gif"),
    s9: require("../images/9s.gif"),
    c10: require("../images/10c.gif"),
    d10: require("../images/10d.gif"),
    h10: require("../images/10h.gif"),
    s10: require("../images/10s.gif"),
    ca: require("../images/ac.gif"),
    da: require("../images/ad.gif"),
    ha: require("../images/ah.gif"),
    sa: require("../images/as.gif"),
    cj: require("../images/jc.gif"),
    dj: require("../images/jd.gif"),
    hj: require("../images/jh.gif"),
    sj: require("../images/js.gif"),
    ck: require("../images/kc.gif"),
    dk: require("../images/kd.gif"),
    hk: require("../images/kh.gif"),
    sk: require("../images/ks.gif"),
    cq: require("../images/qc.gif"),
    dq: require("../images/qd.gif"),
    hq: require("../images/qh.gif"),
    sq: require("../images/qs.gif"),
}