import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { CardImages } from '../Resources/CardImages';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const cardKeys = Object.keys(CardImages);

export default function CustomizedDialogs({ open, onClose, onImageSelect }) {
  // Define the handleImageClick function before using it
  const handleImageClick = (cardKey) => {
    onImageSelect(cardKey); // Call the callback to pass the selected image URL
    onClose(); // Close the modal after image selection
  };

  const imageElements = cardKeys.map((cardKey) => (
    <img
      style={{
        cursor: "pointer",
        width: `${window.innerWidth > 590 ? "125px" : window.innerWidth>355? "100px":window.innerWidth>295? "75px":"55px"}`,
        height: `${window.innerWidth > 590 ? "170px" : window.innerWidth>355? "136px":window.innerWidth>295? "95px":"65px"}`,
      }}
      key={cardKey}
      src={CardImages[cardKey]}
      alt={cardKey}
      onClick={() => handleImageClick(cardKey)} // Call the function when an image is clicked
      className={`mt-4 mx-${
        window.innerWidth > 650 ? "1" : window.innerWidth > 590 ? "3" : "2"
      } img-fluid`}
    />
  ));

  return (
    <>
      {open && (
        <BootstrapDialog
          onClose={onClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Cards in Deck
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
  {imageElements}
</DialogContent>
          <DialogActions>
            <Button className='btn bg-dark text-light px-4 py-2' autoFocus onClick={onClose}>
              Save
            </Button>
          </DialogActions>
        </BootstrapDialog>
      )}
    </>
  );
}
