export const getColor = (color) => {
  if (color === "3black") {
    return {
      img: require("../images/left.gif"),
      text: 'You selected 3 black cards ("left brained") and 2 red cards ("right brained"). This means that you are slightly more left-brained and prefer rational/analytical thought. However, you are relatively balanced and can adapt to different modes of thinking when necessary. In general, you can at times be a bit skeptical. Given your focus on data and results, you want proof. Knowledge and expertise are a cornerstone of your thinking style. [NOTE: Technically you are not left brained.  All of your brain works quite nicely.  In the Personality Poker book, we dispel some of the myths associated with the brain.]',
    };
  } else if (color === "4black") {
    return {
      img: require("../images/left.gif"),
      text: 'You selected 4 black cards ("left brained") and only 1 red card ("right brained"). This means that you are significantly more left-brained than right-brained and prefer rational/analytical thought. In general, you can at times be a bit skeptical. Given your focus on data and results, you want proof. Knowledge and expertise are a cornerstone of your thinking style. You might be described as someone who puts the "NO" in innovation. You find the reasons why things don\'t work.  Your tendency is to use "yeah, but" expressions frequently. The opportunity for you is to maintain an open mind and to consider unconventional/untraditional alternatives. [NOTE: Technically you are not left brained. All of your brain works quite nicely. In the Personality Poker book, we dispel some of the myths associated with the brain.]',
    };
  } else if (color === "5black") {
    return {
      img: require("../images/left.gif"),
      text: 'You selected 5 black cards ("left brained") and no red card ("right brained"). This means that you quite left-brained and prefer rational/analytical thought. In general, you can at times be a bit skeptical. Given your focus on data and results, you want proof. Knowledge and expertise are a cornerstone of your thinking style. You might be described as someone who puts the "NO" in innovation. You find the reasons why things don\'t work. Your tendency is to use "yeah, but" expressions frequently. The opportunity for you is to maintain an open mind and to consider unconventional/untraditional alternatives. [NOTE: Technically you are not left brained. All of your brain works quite nicely. In the Personality Poker book, we dispel some of the myths associated with the brain.]',
    };
  } else if (color === "3red") {
    return {
      img: require("../images/right.gif"),
      text: 'You selected 3 red cards ("right brained") and 2 black cards ("left brained"). This means that you are slightly more right-brained and prefer relational/creative thought. However, you are relatively balanced and can adapt to rational modes of thinking when necessary. You may at times become easily distracted by new opportunities. [NOTE: Technically you are not right brained. All of your brain works quite nicely. In the Personality Poker book, we dispel some of the myths associated with the brain.]',
    };
  } else if (color === "4red") {
    return {
      img: require("../images/right.gif"),
      text: 'You selected 4 red cards ("right brained") and 1 black card ("left brained"). This means that you are more right-brained than left-brained, and prefer relational thought (connections between ideas, experiences, and people). You are someone who might be described as putting the "FUN" in disfunctional. People like to be around you. However, there may be times when you could benefit from more rational thought. Be careful to not chase too many "bright shiny objects". [NOTE: Technically you are not right brained. All of your brain works quite nicely. In the Personality Poker book, we dispel some of the myths associated with the brain.]',
    };
  } else if (color === "5red") {
    return {
      img: require("../images/right.gif"),
      text: 'You selected all red cards ("right brained") and no black card ("left brained"). This means that you are primarily right-brained and prefer relational thought (connections between ideas, experiences, and people). You are someone who might be described as putting the "FUN" in disfunctional. People like to be around you. However, there may be times when you could benefit from more rational thought. Be careful to not chase too many "bright shiny objects". [NOTE: Technically you are not right brained. All of your brain works quite nicely. In the Personality Poker book, we dispel some of the myths associated with the brain.]',
    };
  }
};

export const getPrimaryStrongSuit = (suit) => {
  if (suit === "spades") {
    return {
      img: require("../images/spades.gif"),
      text: (
        <>
          <div className={`${window.innerWidth > 500? "d-flex":"d-flex justify-content-center"}`}>
            <img
              src="../images/spades.gif"
              style={{
                width: window.innerWidth >= 500 ? "150px" : "100px",
                height: window.innerWidth >= 500 ? "150px" : "100px",
                flex: window.innerWidth>=500? "12%":"none",
              }}
              alt="spade"
            />
            <p id="p2Text" style={{ flex: window.innerWidth>=500? "88%":"none" }}>
              Your primary strong suit is the{" "}<b>spade</b>. This means that you
              are analytical and data-oriented. You like facts and are a bit
              more cerebral than the other styles. You make decisions based on
              analysis and are known for your deep knowledge. Typical careers
              include engineers, philosophers, scientists, researchers and
              professors.
            </p>
          </div>
          <p id="p2Text">
            Spades are{" "}<b>research-driven, analytical, and exacting</b>, people
            who love to jump chest-deep into the data on hand, then skillfully
            break it down until reaching a cohesive understanding of the
            predicaments and challenges within any organization.
          </p>
          <p id="p2Text">
            Where might you find these intellectual zealots? Look in libraries,
            research labs, information technology departments, banks, and at the
            CFOs of major corporations. Spades can range from the stereotypical
            &quot;geek&quot; who knows everything about technology and science
            to the accountant who prepares your taxes. Any job where the
            gathering and synthesizing of facts and figures is the primary
            responsibility is a natural magnet for most spades.
          </p>
          <p id="p2Text">
            Aside from the formulation of strategic direction, another critical
            spade-driven function exists within the realm of research and
            development (R&amp;D). R&amp;D departments are teaming with spades
            who are patiently working to uncover the next breakthrough
            discovery. This is a nurturing environment for spades who are
            allowed to work at their own pace unfettered by deadlines.
          </p>
          <p id="p2Text">
            Unlike their diamond counterparts, whose attention span is shorter,
            spades have the internal fortitude to withstand the time it takes to
            unravel exceptionally complex problems, be it ten minutes or ten
            years.
          </p>
          <p id="p2Text" style={{maxWidth:"100%" , textAlign: "center", marginBottom: 0 }}>
            _______________
          </p>
        </>
      ),
    };
  } else if (suit === "hearts") {
    return {
      img: require("../images/hearts.gif"),
      text: (
        <>
          <div  className={`${window.innerWidth > 500? "d-flex":"d-flex justify-content-center"}`}>
            <img
              style={{
                width: window.innerWidth >= 500 ? "150px" : "100px",
                height: window.innerWidth >= 500 ? "150px" : "100px",
                flex: window.innerWidth>=500? "12%":"none",
              }}
              src="../images/hearts.gif"
              alt="hearts"
            />
            <p id="p2Text" style={{ flex: window.innerWidth>=500? "88%":"none" }}>
              Your primary &quot;strong suit&quot; is{" "}<b>hearts</b>. Hearts are
              all about{" "}<b>people and relationships</b>. This means you are most
              likely merciful, harmonious, empathetic, and compassionate. The
              mission of hearts is to ensure that the &quot;people aspect&quot;
              of any business or personal venture is a well-oiled machine.
            </p>
          </div>
          <p id="p2Text">
            Hearts believe that if you can't take care of your own people, they
            won't be able to take care of anyone else. This empathetic view is
            clearly evident in what hearts do every day. Hearts make decisions
            based on the situation at hand. They understand there are unique
            circumstances that could affect the choices that should be made.
            They tend to shy away from a one-size-fits-all approach when
            determining the right steps to take, preferring to personalize their
            decision making. They also look at the value of the outcome they (or
            an organization) are trying to produce to determine if it's going to
            benefit the individuals who are associated with the process, be it
            the employees, customers, or stakeholders. They ask the question,
            &quot;Who will this benefit?&quot; If they are unable to answer that
            satisfactorily, they will look for an alternate course of action.
          </p>
          <p id="p2Text" style={{maxWidth:"100%" , textAlign: "center", marginBottom: 0 }}>
            _______________
          </p>
        </>
      ),
    };
  } else if (suit === "clubs") {
    return {
      img: require("../images/clubs.gif"),
      text: (
        <>
          <div className={`${window.innerWidth > 500? "d-flex":"d-flex justify-content-center"}`}>
            <img
              style={{
                width: window.innerWidth >= 500 ? "150px" : "100px",
                height: window.innerWidth >= 500 ? "150px" : "100px",
                flex: window.innerWidth>=500? "12%":"none",
              }}
              src="../images/clubs.gif"
              alt="Clubs"
            />
            <p id="p2Text" style={{ flex: window.innerWidth>=500? "88%":"none" }}>
              Your primary{" "}<b>strong suit</b>{" "}is the club. The old adage, "
              <b>Plan the work, work the plan</b>", best typifies the club
              mentality. Once organizational objectives have been established,
              it is time to enlist these driven, dedicated, and hardworking
              individuals in your project.
            </p>
          </div>
          <p id="p2Text">
            When executed in tandem, the two critical functions of planning and
            action can rapidly move the organization through the change that is
            needed for companies to stay ahead of their competition. While high
            and low clubs share the same bottom-line orientation, their
            contributions differ significantly. The low clubs crave structure
            and organization and are experts at translating the overall goal
            into a plan with bite-sized, manageable steps that will ensure the
            job is done on time and within budget. These skills pair nicely with
            the forward-charging high clubs, &quot;the doers,&quot; who will
            implement the plan and drive toward results. Unlike their low club
            counterparts, high clubs are typically more aggressive and covet
            immediate gratification.
            <i>
              If you tested as a high/low club, you will find that information
              below.
            </i>
          </p>
          <p id="p2Text">
            However, both are take-charge by nature and have a strong results
            orientation.
          </p>
          <p id="p2Text" style={{maxWidth:"100%" , textAlign: "center", marginBottom: 0 }}>
            _______________
          </p>
        </>
      ),
    };
  } else if (suit === "diamonds") {
    return {
      img: require("../images/diamonds.gif"),
      text: (
        <>
           <div className={`${window.innerWidth > 500? "d-flex":"d-flex justify-content-center"}`}>
            <img
              src="../images/diamonds.gif"
              style={{
                width: window.innerWidth >= 500 ? "150px" : "100px",
                height: window.innerWidth >= 500 ? "150px" : "100px",
                flex: window.innerWidth>=500? "12%":"none",
              }}
              alt="diamond"
            />
            <p id="p2Text"  style={{ flex: window.innerWidth>=500? "88%":"none" }}>
              Your strong suit is a{" "}<b>diamond</b>. In general, diamonds prefer
              ideas and experiences and are &quot;multifaceted&quot;
              individuals. Entrepreneur and creator of the Virgin brand, Sir
              Richard Branson, is representative of the dynamic and imaginative
              diamonds who develop game-changing ideas.
            </p>
          </div>
          <p id="p2Text">
            When looking at a diamond, it's easy to see that their overarching
            theme is{" "}<b>novelty</b>.{" "}<b>Freedom</b>{" "}and the{" "}<b>desire for variety</b>{" "}are
            important to these multifaceted and unpredictable types. As a
            result, diamonds tend to make decisions based on what is enjoyable.
            <br />
          </p>
          <p id="p2Text">
            When an interesting idea is generated it often influences the
            direction a diamond takes; that direction seems to be forever
            changing and unpredictable. Diamonds are often the champions of
            change, sometimes simply advocating change for its own sake.
            {" "}<b>Impulsive</b>{" "}by nature, some diamonds favor keeping their
            options open, preferring to make decisions in the moment based on
            what feels right. They rarely commit to anything in the distant
            future as this might limit their options and box them in.
          </p>
          <p id="p2Text">
            Additionally, diamonds typically prefer to &quot;{" "}<b>go with the flow</b>{" "}.&quot; This trait is often indicative of the
            hobbies and professions they choose. Artists have no set idea what
            the painting will reveal until the final brushstroke. Similarly,
            musicians enjoy jazz because they can improvise on a melody,
            sometimes bringing them quite far afield. In the business world,
            diamonds operate in much the same manner. Goals do little to
            motivate a diamond, and can often be viewed as stifling. Instead,
            they prefer to meander, often producing results beyond what any
            preset goal could foresee. They appreciate the journey much more
            than the destination.
          </p>
          <p id="p2Text">
            The nature of your primary diamond style is typically dictated by
            your secondary style (if indicated below). Those with a spade
            secondary are low/inward energy diamonds and are primarily
            &quot;thinkers.&quot; The difference is, spades are analytical
            thinkers whereas low diamonds are creative thinkers. When you have a
            high heart secondary, you are most likely a high/outward energy
            diamond who is an &quot;influencer.&quot;
          </p>
          <p id="p2Text">
            Regardless, all diamonds share a lot in common and can be easily
            confused.
          </p>
          <p id="p2Text" style={{maxWidth:"100%" , textAlign: "center", marginBottom: 0 }}>
            _______________
          </p>
        </>
      ),
    };
  }
};

export const getHighOrLow = (suit) => {
  if (suit === "highc") {
    return {
      text: (
        <>
          <h2 className="text-left my-4">High Strong Suit (10 - A)</h2>
          <p id="p2Text">
            You appear to be a{" "}<b>high club</b>{" "}which means that you are focused
            on the bottom line. You tend to be competitive and people come to
            you when they want to get things done. You are goal-driven and
            thrive when you are in action. You are motivated by results and are
            not satisfied unless you achieve your goal.
          </p>
          <p id="p2Text">
            The comedian Larry the Cable Guy coined the popular catchphrase{" "}
            &quot;<b>Git-R-Done</b>.&quot;{" "}This attitude is undoubtedly the
            mantra of anyone who is a die-hard high club.
          </p>
          <p id="p2Text">
            When coordinating a team for any project, at least one or two high
            clubs must be on the roster. These{" "}<b>goal-oriented, results-producing, tough-minded</b>{" "}workers will
            stop at nothing short of achievement. Their commitment to producing
            overshadows any obstacle that could present itself along the path to
            success, whether office politics, dissension among the ranks,
            process breakdowns, or clashes in personality with other members of
            the team.
          </p>
          <p id="p2Text">
            Since high clubs do not have the same drive to be liked as their
            heart colleagues, they are more comfortable making unpopular but
            necessary decisions. Even though they are exposed to the scalding
            words of naysayers who disagree with the direction taken, they will
            continue to forge ahead oblivious to the pressure that might crumble
            those with a weaker fortitude. High clubs make great accountability
            managers because their commitments are as binding as contracts cast
            in stone. And not surprisingly, they expect the same integrity from
            others.
          </p>
          <p id="p2Text">
            Unlike the low clubs, the high clubs rarely, if ever, experience
            discomfort with change. As a matter of fact, they are the stewards
            of change. They are able to create results even when the means of
            achieving them are not well defined. They are decisive and have the
            ability to make decisions on the fly. They do not fear ambiguity,
            but will simply improvise and figure it out as they go along. To
            them, failure is not an option.
          </p>
          <p id="p2Text">
            Given this &quot;get it done&quot; mentality, it is not surprising
            to see high clubs at the helm of many large organizations.
          </p>
        </>
      ),
    };
  } else if (suit === "highd") {
    return {
      text: (
        <>
          <h2 className="text-left my-4">High Strong Suit (10 - A)</h2>
          <p id="p2Text">
            You are a {" "}<b>high diamond</b>{" "}which means that you enjoy engaging in
            new experiences and embarking upon uncharted territory.
          </p>
          <p id="p2Text">
            You prefer variety over routine activities. A motto that fits your
            philosophy is &quot;It's the journey, not the destination.&quot;
          </p>
          <p id="p2Text">
            <b>Daring and adventurous</b>, high diamonds love to walk on the
            wild side and experience all that life has to offer. When traveling
            to distant places, they toss the itinerary, preferring to let the
            wind blow them in various directions. New and varied activities are
            the high diamond's inspiration. To restrict them to a daily routine
            would be tantamount to planting a sunflower in a room with no
            windows and no water. It would quickly wither and die.
          </p>
          <p id="p2Text">
            High diamonds are known for regaling friends, family members, and
            anyone who likes to live vicariously with entertaining tales of
            their escapades. They are continuously on the go, lively, energetic,
            the life of the party. They live spontaneous and flexible lives,
            garnering a rich array of experiences from which to draw when
            tackling new projects. Skilled at speaking extemporaneously, they
            are at ease making presentations to both small and large audiences.
            They can improvise in a way that makes an often-presented speech sound
            unique each and every time delivered. Many professional speakers are
            high diamonds.
          </p>
          <p id="p2Text">
            High diamonds and high clubs (action-oriented individuals) are often
            confused because of their more outgoing and on-the-go nature;
            however, the distinction between the two is significant. The
            take-charge demeanor of high clubs is ultimately for the purpose of
            completing tasks so that each can be checked off their list. They
            are pushing for results. The high diamonds, conversely, are being
            pulled by what they perceive as enjoyable. They are often easily
            distracted and change directions frequently, taking on new endeavors
            with a fervor unmatched by most.
          </p>
          <p id="p2Text">
            Similarly, high diamonds and high hearts (relationship-oriented 
            networkers) share many common traits. Both derive energy from being
            with others. While their social activities look similar on the
            surface, their internal motivations are not. High hearts enjoy
            networking with people for both social and business endeavors.
            Everyone provides value, and hearts are more apt to keep in contact.
            High diamonds, however, choose to surround themselves with others
            based on the kinds of experiences they can share. They will enjoy
            the company of another for the time being and may not do so again
            until the circumstances warrant it.
          </p>
          <p id="p2Text">
            High diamonds are often found heading up organizations because of
            their engaging demeanor and infectious ways. They enjoy being their
            own boss, which gives them the flexibility to focus their attention
            on those activities that provide satisfaction. They are well suited
            to creative consulting roles because it affords them the opportunity
            to work with a variety of projects and myriad people. It also allows
            them to steer away from the more administrative tasks associated
            with the implementation of projects.
          </p>{" "}
        </>
      ),
    };
  } else if (suit === "highh") {
    return {
      text: (
        <>
          <h2 className="text-left my-4">High Strong Suit (10 - A)</h2>
          <p id="p2Text">
            You appear to be a{" "}
            <b>
              <u>high heart</u>
            </b>{" "}
            which means that you are a highly social person who enjoys spending
            your time with a variety of people.{" "}
          </p>
          <p id="p2Text">
            You are energized when you are in the presence of others and tend to
            thrive in the spotlight. Networking with others is one of your
            innate skills.{" "}
          </p>
          <p id="p2Text">
            You might live by the motto -{" "}
            <i>strangers are just friends you haven’t yet met.</i>{" "}
          </p>
          <p id="p2Text">
            While both high and low hearts are all about the people, your
            approach to them can be radically different. As a{" "}<b>high heart</b>,
            you have a &quot;more the merrier&quot; attitude. High hearts tend to
            be highly exuberant, playful, vivacious, and outgoing individuals
            who love networking with just about anyone.{" "}
          </p>
          <p id="p2Text">
            On the other hand, the low hearts like to cultivate a smaller number
            of more meaningful relationships. They are more gentle and
            compassionate, sensitive and devoted. They have little interest in
            being the center of attention, but try to make each person they work
            with feel as though they are the center of theirs.{" "}
          </p>
        </>
      ),
    };
  } else if (suit === "highs") {
    return {
      text: (
        <>
          <h2 className="text-left my-4">High Strong Suit (10 - A)</h2>
          <p id="p2Text">
            You appear to be a{" "}<b>high spade</b>{" "}which means that you value being
            the guru in your area of expertise, and relish the opportunity to
            impart my knowledge to others. You would make a good professor and
            enjoy getting into meaningful debates over important ideas. You
            could spend hours engaging in intellectually stimulating
            conversations.{" "}
          </p>
        </>
      ),
    };
  } else if (suit === "lowc") {
    return {
      text: (
        <>
          <h2 className="text-left my-4">Low Strong Suit (5 - 9)</h2>
          <p id="p2Text">
            You appear to be a{" "}<b>low club</b>{" "}which means that you are a
            planner and often feel lost when you do not have a clear direction
            spelled out. You are an organizer who likes everything in its place.
            You are most comfortable when you know how and when things will be
            done. People consider you disciplined and dependable.
          </p>
          <p id="p2Text">
            Low clubs espouse the advice of Benjamin Franklin, who warned that{" "}
            <b>&quot;By failing to prepare, you are preparing to fail.&quot;</b>{" "}
            These are not merely words but the mantra by which the low clubs
            live. They are methodical, systematic, and structured planners who
            arrange their work to induce predictability and reliability. Their
            masterfully crafted plans clearly articulate roles, dependencies,
            budgets, and timelines. Their goals are &quot;SMART&quot;- that is,
            Specific, Measurable, Attainable, Realistic, and Time-bound. This
            ensures that everyone knows when success has been achieved so that
            it is not left to individual interpretation.
          </p>
          <p id="p2Text">
            Low clubs are self-disciplined and can perform well on their own
            without micromanagement. Procrastination is not in their vocabulary.
            Once they embark upon a particular activity, they prefer to finish
            the task at hand before moving on to the next. In Aesop's fables,
            the slow and steady turtle best represents the tenacity of the low
            club. Unlike the more scattered diamonds, who enjoy getting lost in
            thought, low clubs are purposeful and stay on task, making them more
            successful when facing tight timelines.
          </p>
          <p id="p2Text">
            Organizational skills are pervasive with a low club, not only when
            creating plans. For them,{" "}<b>orderliness</b>{" "}is an innate
            capability. Clubs continually look for ways to downsize unwanted
            items and unnecessary steps in processes, increasing organizational
            efficiencies. Because they place a higher value on organization than
            sentimentality, they do not retain anything where there is no
            specific or immediate use, reducing clutter and increasing ease of
            functionality. A low club's workstation is more orderly than most,
            as they file items where they belong after use or create a place for
            them if one does not already exist. These skills make them well
            suited to any position requiring a methodical individual:
            administrative assistant, project manager, the managers of large
            change programs, or even the chief operating officer.
          </p>
        </>
      ),
    };
  } else if (suit === "lowd") {
    return {
      text: (
        <>
          <h2 className="text-left my-4">Low Strong Suit (5 - 9)</h2>
          <p id="p2Text">
            It also appears that you are a{" "}<b>low diamond</b>{" "}which means that
            you are someone who is creative.{" "}
          </p>
          <p id="p2Text">
            You willingly entertain new ideas and am known as someone who thinks
            differently than others. You have a knack for developing unique,
            inventive, and breakthrough ideas.{" "}
          </p>
          <p id="p2Text">
            Low diamonds, in general, are creative, innovative problem solvers
            who generate new product ideas and challenge the status quo at every
            turn, keeping the other employees, as well as the competition, on
            their toes.
          </p>
          <p id="p2Text">
            Low diamonds are{" "}<b>idea-oriented thinkers</b>, often the creators
            of breakthrough innovations. Their interest in variety and adventure
            is on a more cerebral level than that of high diamonds. They enjoy
            the thrill of the hunt for solutions and use creative,
            out-of-the-box methods for handling challenges, even those they
            haven't yet identified. Endowed with a sixth sense, they typically
            make their decisions based on hunches, for good or ill. They can
            synthesize the directly relevant data with seemingly unrelated
            information to create new ideas and solutions that others couldn't
            even begin to dream of.
          </p>
          <p id="p2Text">
            Low diamonds are resilient by nature. If one opportunity does not
            pan out, they quickly switch gears to the next possibility.
            Ambiguity is never much of a problem for diamonds. Actually, they
            enjoy the adventure of wallowing in the unknown. They simply see it
            as just another mental adventure. They are{" "}<b>curious</b>{" "}
            individuals and love to look at a problem from a variety of angles.
            They can be both outgoing and reserved, depending on the situation.
            One day they might want to be part of a big group and the next
            they'll want peace and quiet. Creative types are notorious for being
            harder to type than most due to their multifaceted personalities.
          </p>
        </>
      ),
    };
  } else if (suit === "lowh") {
    return {
      text: (
        <>
          <h2 className="text-left my-4">Low Strong Suit (5 - 9)</h2>
          <p id="p2Text">
            You appear to be a{" "}
            <u>
              <b>low heart</b>
            </u>{" "}
            which means that you are a person who places great value on deep and
            significant relationships.{" "}
          </p>
          <p id="p2Text">
            When it comes to friendships, you prefer quality over quantity and
            are willing to do just about anything to support the people who
            matter most to you.
          </p>
          <p id="p2Text">
            While both high and low hearts are all about the people, your
            approach to them can be radically different. As a{" "}<b>low heart</b>,{" "}
            you like to cultivate a smaller number of more meaningful
            relationships. You are gentle and compassionate, sensitive and
            devoted. You probably have little interest in being the center of
            attention, but try to make each person you work with feel as though
            they are the center of theirs.{" "}
          </p>
          <p id="p2Text">
            On the other hand, the high hearts have a &quot;more the
            merrier&quot; attitude. They tend to be highly exuberant, playful,
            vivacious, and outgoing individuals who love networking with just
            about anyone.{" "}
          </p>
        </>
      ),
    };
  } else if (suit === "lows") {
    return {
      text: (
        <>
          <h2 className="text-left my-4">Low Strong Suit (5 - 9)</h2>
          <p id="p2Text">
            You appear to be a {" "}<b>low spade</b>{" "}which means that for you,
            knowledge is power. You love to gather and analyze data. You rarely
            accept things at face value and prefer having facts and objective
            proof when making decisions. You could spend hours studying,
            reading, or searching the Internet.{" "}
          </p>
        </>
      ),
    };
  }
};

export const getIntroOrExtro = (suit) => {
  if (suit === "Introvert") {
    return {
      text: (
        <>
          <h2 className="text-left my-4">Introvert</h2>
          <p id="p2Text">
            Based on the cards you selected, you appear to be more{" "}
            <b>introverted</b>.{" "}
          </p>
          <p id="p2Text">
            Although most people think of an introvert as someone who likes to
            be alone, here we mean something different. This means that you
            typically prefer more solitary work. You do your best when working
            on your own or with small groups. However, taken more broadly,
            introversion also includes a tendency to be more easily overwhelmed
            by stimuli that are deemed too intense. You prefer predictability
            and a low likelihood of risk.{" "}
          </p>
          <p id="p2Text">
            For these reasons, introverts may be known for their depth of
            experiences rather than their breadth. They value time to reflect
            while also attaching importance to getting things done in an
            organized manner. We refer to these energy styles as &quot; inwardly
            focused.&quot; In Personality Poker, these individuals are
            represented by the 5, 6, 7, 8, and 9 cards.{" "}
          </p>
          <p id="p2Text">
            Don't confuse introversion with shyness or reclusiveness. They are
            not related. Introverts prefer more solitary work whereas shy
            individuals work alone out of fear or anxiety at the prospect of
            performing around others.{" "}
          </p>
        </>
      ),
    };
  } else if (suit === "Extrovert") {
    return {
      text: (
        <>
          <h2 className="text-left my-4">Extrovert</h2>
          <p id="p2Text">
            Based on the cards you selected, you appear to be more{" "}
            <b>extroverted</b>.{" "}
          </p>
          <p id="p2Text">
            Those at the extroverted end of psychologists scale are inclined to
            thrive on higher energy activities. They work well with groups of
            people and are often group leaders.{" "}
          </p>
          <p id="p2Text">
            Although they may not be as good at focusing on single tasks, they
            get energy from action rather than reflection and are known for
            their ability to motivate others to get things done. In fact, when
            they are inactive, their level of energy and motivation often
            declines. Therefore they seek out greater levels of stimuli and are
            prone to more risk-taking.{" "}
          </p>
          <p id="p2Text">
            We refer to this energy style as outwardly focused. Those who have
            primarily &quot;outwardly focused&quot; energy-style cards are
            generally better leaders. They work better with others and have what
            is perceived as a higher energy level. This makes them seem more
            dynamic.{" "}
          </p>
        </>
      ),
    };
  }
};

export const getSecondary = (suit) => {
  if (suit === "spades") {
    return {
      text: (
        <>
          <p id="p2Text">
            You have a spade{" "}<b>secondary</b>. This means that in addition to
            your strong suit, you are also analytical and data-oriented. You
            like{" "}<b>facts</b>{" "}and are a bit more cerebral than the other styles.
            Spades typically make decisions based on analysis and are known for
            your deep knowledge.{" "}
          </p>
        </>
      ),
    };
  } else if (suit === "hearts") {
    return {
      text: (
        <>
          <p id="p2Text">
            You have a {" "}<b>heart</b>{" "}secondary. Hearts are all about
            relationships. They make decisions based on what others think. Some
            are empathetic and supportive. Others are social butterflies and
            master networkers. But at their core, hearts are all about people.
          </p>
        </>
      ),
    };
  } else if (suit === "diamonds") {
    return {
      text: (
        <>
          <p id="p2Text">
            You have a{" "}<b>diamond</b>{" "}secondary. Diamonds are the stereotypical{" "}
            <b>creative</b>{" "}individuals who like new ideas and new experiences.
            Diamonds often make decisions based on what is &quot;enjoyable&quot;
            and typically don't care where they are going, as long as they are
            having a good time getting there.{" "}
          </p>
        </>
      ),
    };
  } else if (suit === "clubs") {
    return {
      text: (
        <>
          <p id="p2Text">
            You have a{" "}<b>club</b>{" "}secondary. In general, clubs are the people
            who{" "}<b>plan the work and work the plans</b>. In addition to your
            strong suit, you probably also like structure and/or focusing on
            bottom-line results.
          </p>
        </>
      ),
    };
  }
};

export const getOpposite = (suit) => {
  if (suit === "spades") {
    return {
      text: (
        <>
          <p id="p2Text">
            It appears that your &quot;opposite&quot; suit are the <b>spades</b>
            .{" "}
          </p>
          <p id="p2Text">
            Spades are{" "}<b>analytical and fact-driven</b>. You, on the other
            hand, tend to be more emotional and make decisions based on instinct
            or gut.{" "}
          </p>
          <p id="p2Text">
            You (and your business) would benefit from having spades on your
            team.{" "}
          </p>
        </>
      ),
    };
  } else if (suit === "hearts") {
    return {
      text: (
        <>
          <p id="p2Text">
            It appears that your &quot;opposite&quot; suit are the{" "}<b>hearts</b>
            .{" "}
          </p>
          <p id="p2Text">
            This does not mean that you lack heart. However, you are more
            analytical and tend to be driven more by facts than emotions.{" "}
          </p>
          <p id="p2Text">
            You (and your business) would benefit from having hearts on your
            team as they can provide a more &quot;feeling&quot; perspective.{" "}
          </p>
        </>
      ),
    };
  } else if (suit === "diamonds") {
    return {
      text: (
        <>
          <p id="p2Text">
            It appears that your &quot;opposite&quot; suit are the{" "}
            <b>diamonds</b>.{" "}
          </p>
          <p id="p2Text">
            Diamonds are{" "}<b>creative</b>{" "}and tend to be{" "}<b>unconventional</b>.{" "}
            You, on the other hand, are more conventional.{" "}
          </p>
          <p id="p2Text">
            You (and your business) would benefit from having diamonds on your
            team.{" "}
          </p>
        </>
      ),
    };
  } else if (suit === "clubs") {
    return {
      text: (
        <>
          <p id="p2Text">
            It appears that your &quot;opposite&quot; suit are the{" "}<b>clubs</b>.
            Clubs are great at<b>{" "}planning the work and working the plan</b>.{" "}
          </p>
          <p id="p2Text">
            You, on the other hand, are more unconventional, choosing to
            meander.{" "}
          </p>
          <p id="p2Text">
            You (and your business) would benefit from having clubs on your
            team.{" "}
          </p>
        </>
      ),
    };
  }
};

// change message summary text here :
export const getPrimarySummary = (suit) => {
  if (suit === "spades") {
    return ("Spades are analytical, research-driven individuals with a penchant for data and deep knowledge, often found in roles like engineers, scientists, and CFOs of major corporations. They thrive in environments that require the gathering and synthesizing of facts, such as R&D departments, and can patiently work through complex problems over extended periods. Unlike quick-moving diamonds, spades possess the endurance to address challenges, whether they take minutes or years to solve.");
    
  } else if (suit === "hearts") {
    return ("Some hearts prioritize people and relationships, characterized by their empathy, compassion, and harmonious nature. They believe in taking care of individuals within an organization, making decisions based on unique circumstances rather than a one-size-fits-all approach. Other hearts are more focused on the quantity of relationships and are masterful networkers.");

  } else if (suit === "clubs") {
    return ("The primary trait of the club is to \"Plan the work, work the plan,\" making them essential for achieving organizational objectives due to their dedication and hard work. Some clubs excel in creating structured plans by breaking down goals into manageable steps, ensuring timely and budgeted completion. In contrast, other clubs are proactive implementers, seeking immediate results and often displaying a more aggressive approach.");

  } else if (suit === "diamonds") {
    return ("Diamonds are multifaceted individuals who value novelty, freedom, and variety, often making decisions based on enjoyment and the allure of the moment. They are impulsive, champion change, and prefer to \"go with the flow,\" shying away from long-term commitments that might limit their options. While some diamonds, like artists or musicians, appreciate the journey over the destination, their nature can be influenced by secondary styles; for instance, spade secondaries make them analytical thinkers, while heart secondaries turn them into influencers.");
  }
};
// Short summary for Twitter
export const getShortSummary = (suit) => {
  if (suit === "spades") {
    return ("Spades are analytical and thrive on data and knowledge, commonly found as engineers, scientists, and CFOs.");
    
  } else if (suit === "hearts") {
    return ("Hearts emphasize relationships and people. Some are empathetic while others are great networkers.");

  } else if (suit === "clubs") {
    return ("Clubs embody \"Plan the work, work the plan.\" Clubs are structured and goal-oriented.");

  } else if (suit === "diamonds") {
    return ("Diamonds are multifaceted individuals who value novelty, freedom, and variety.");
  }
};